import React from 'react'
import { Container, Typography, Button, Grid } from '@material-ui/core'
import useStyles from './styles'
import CartItem from './CartItem/CartItem.jsx'
import { Link } from 'react-router-dom'

const Cart = ({ cart , handleUpdateCartQuantity, handleRemoveFromCart, handleEmptyCart}) => {
      const classes = useStyles()
      const EmptyCart = () =>{
            return <Typography style={{minHeight: 480}} variant="subtitle1">
                  You have no items in your shopping cart, 
                        <Link to="/" className={classes.link}> start adding some!</Link>
                  </Typography>
      }
      const FilledCart = () =>{
            return (<>
                  <Grid container justifyContent='center' spacing={3}>
                        {cart.line_items.map((item)=> (
                            <Grid item sx={12} md={4} sm={6} lg={3} key={item.id}>
                                  <CartItem item={item} handleUpdateCartQuantity={handleUpdateCartQuantity} handleRemoveFromCart={handleRemoveFromCart}/>
                            </Grid>
                        ))}
                  </Grid>
                  <div className={classes.cardDetails}>
                        <Typography variant="h4">Subtotal: {cart.subtotal.formatted_with_symbol}</Typography>
                        <div className={classes.btns}>
                              <Button className={classes.emptyButton} size="large" type="button" variant="contained" color="secondary" onClick={handleEmptyCart}>Empty Cart</Button>
                              <Button component={Link} to="/checkout" className={classes.checkoutButton} size="large" type="button" variant="contained" color="primary">Checkout</Button>
                        </div>
                  </div>
                  </>)
      }

      if(!cart.line_items) return "Loading..."
  return (
      <Container>
            <div className={classes.toolbar}/>
            <Typography className={classes.title} variant='h3' gutterBottom>Your Shopping Cart</Typography>
            {!cart.line_items.length ? <EmptyCart /> : <FilledCart />}
       </Container>
  )
}

export default Cart