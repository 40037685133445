import React, { useState, useEffect} from 'react'
import { commerce } from './lib/commerce.js'
import { Product, NavBar, Cart, Checkout, Footer, Banner } from './components'
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import './App.css'

function App() {
  const [products, setProducts] = useState([])
  const [cart, setCart] = useState({})
  const [order, setOrder] = useState({})
  const [errorMessage, setErrorMessage] = useState('')

  const fethProducts = async () => {
    const { data } = await commerce.products.list()

    setProducts(data)
  }
  const fetchCart = async () =>{
    setCart(await commerce.cart.retrieve())
  }
  const handleAddToCart = async (productId, quantity) =>{
    const { cart } = await commerce.cart.add(productId, quantity)

    setCart(cart)
  }
  const handleUpdateCartQuantity = async (productId, quantity) =>{
    const { cart } = await commerce.cart.update(productId, {quantity})

    setCart( cart )
  }
  const handleRemoveFromCart = async ( productId ) =>{
    const { cart } = await commerce.cart.remove(productId)

    setCart(cart)
  }
  const handleEmptyCart = async () =>{
    const { cart } = await commerce.cart.empty()

    setCart(cart)
  }
  const refreshCart = async () =>{
    const newCart = await commerce.cart.refresh()

    setCart(newCart)
  }
  const handleCaptureCheckout = async (checkoutTokenId, newOrder ) =>{
    try {
      const incomingOrder = await commerce.checkout.capture(checkoutTokenId, newOrder)

      setOrder(incomingOrder)
      refreshCart()
    } catch (error) {
      setErrorMessage(error.data.error.message)
    }

  }

  useEffect(()=>{
    fethProducts()
    fetchCart()
  }, [])

  console.log(cart)
  return (
    <Router>
      <div>
      <NavBar totalItems={cart.total_items}/>
      <Switch>
        <Route exact path="/">
          <>
          {products && <Banner products={products} onAddToCart={handleAddToCart}/>}
            <Product  products={products} onAddToCart={handleAddToCart}/>
          </>
        </Route>
        <Route axact path="/cart">
          <Cart cart={cart} 
          handleUpdateCartQuantity={handleUpdateCartQuantity} 
          handleRemoveFromCart={handleRemoveFromCart} 
          handleEmptyCart={handleEmptyCart}/>
        </Route>
        <Route exact path="/checkout">
          <Checkout cart={cart} order={order} onCaptureCheckout={handleCaptureCheckout} error={errorMessage}/>
        </Route>
      </Switch>
      <Footer />
    </div>
    </Router>

  );
}

export default App;
